import Script from 'next/script';

const ExitBeeScript = () => {
  return (
    <Script
      id="exitbee-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
 class CsvDomainMapper {
        constructor(csvUrl) {
            this.csvUrl = csvUrl;
        }
        getIdByDomain = (domain) => {
            const fetchCsv = () => fetch(this.csvUrl)
                .then(response => response.ok ? response.text() : Promise.reject())
                .then(csvData => csvData.split("\\n").slice(1).reduce((map, line) => {
                    const [dom, id] = line.split(",").map(s => s.trim());
                    if (dom && id) map[dom.replace(/^www\\./, '')] = id;
                    return map;
                }, {}));
            domain = domain.replace(/^www\\./, '');
            return this.domainIdMap ? Promise.resolve(this.domainIdMap[domain] || null) :
                fetchCsv().then(map => (this.domainIdMap = map)[domain] || null);
        }
    }
    (function (e, x, i, t, b) {e["ExitBeeObject"] = b;e[b] = e[b] || 
    function () { (e[b].args = e[b].args || []).push(arguments);};
    a = x.createElement(i), m = x.getElementsByTagName(i)[0];
    a.async = 1;a.src = t;m.parentNode.insertBefore(a, m) })
    (window, document, "script", "https://cdn.exitbee.com/xtb.min.js", "xtb") 
    
    const mapper = new CsvDomainMapper('https://cdn.exitbee.com/csvs/1xlSiteIds.csv');
    mapper.getIdByDomain(window.location.hostname).then(siteId => xtb("loadSite", siteId));`
      }}
    />
  );
};

export default ExitBeeScript;
